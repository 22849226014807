import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PopupComponent from './popup-component'
import { clearStorage } from '../../services/session-service'
import { setCurrentUser } from '../../slices/auth-slice'
import { useDispatch } from 'react-redux'
import { apiBaseUrl, getSessionToken } from '../../utils/api-utils'
import { LOGOUT_URL } from '../../services/constant-veriable-service'

function ErrorPopup(props) {
  const [isOpen, setOpenPopup] = useState(false)
  const [isOpenError, setErrorPopup] = useState(false)
  const dispatch = useDispatch()
  let timeOut = null
  const handleOk = () => {
    setOpenPopup(false)
    setErrorPopup(false)
    logout()
  }
  useEffect(() => {
    setOpenPopup(props.isOpen)
    setErrorPopup(props.isOpen)
    if (props.isOpen) {
      timeOut = setTimeout(() => {
        logout()
      }, 10000)
    }
    return () => clearTimeout(timeOut)
  }, [props.isOpen])
  const logout = () => {
    dispatch(
      setCurrentUser({
        currentUser: { email: null, userId: null }
      })
    )
    clearStorage()
    window.location.href = apiBaseUrl() + LOGOUT_URL + getSessionToken()
  }
  return (
    <div>
      <PopupComponent
        isOpen={isOpen}
        handleOk={handleOk}
        setOpenPopup={setOpenPopup}
        popupTitle={'Session expired'}
        disableClosing={isOpenError}
      >
        <h3 className="fontFamily">
          {props.message ||
            `Your session has expired. You will be redirected to login page after
          10 seconds.`}
        </h3>
      </PopupComponent>
    </div>
  )
}
ErrorPopup.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.any
}
export default ErrorPopup
