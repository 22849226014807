import { getWithAuth, postWithAuth } from '../utils/api-utils'

export function fetchUserList(params) {
  const url = '/api/v1/ldap-users?' + params
  return getWithAuth(url)
}

export function addUser(values) {
  return postWithAuth('/api/v1/ldap-users', {
    firstName: values.firstName,
    lastName: values.lastName,
    password: values.password,
    uidSuffix: values.ldapUidSuffix,
    email: values.email
  })
}
