import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import './sidebar.scss'
import 'font-awesome/css/font-awesome.min.css'
import logo from '../../images/MI_Logo.svg'
import { getLocalStorage } from '../../services/session-service'
import { LOGIN_DETAILS, ROLES } from '../../services/constant-veriable-service'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../slices/auth-slice'

const { Sider } = Layout

const Sidebar = () => {
  const [defaultSelectedKey, setDefaultSelectedKey] = useState('1')
  const location = useLocation()
  const authObj = useSelector(selectAuth)
  const pathName = location.pathname
  const currentRole =
    getLocalStorage(LOGIN_DETAILS) && getLocalStorage(LOGIN_DETAILS).role
      ? getLocalStorage(LOGIN_DETAILS).role
      : ''
  const currentUserId =
    authObj && authObj.currentUser && authObj.currentUser.userId
      ? authObj.currentUser.userId
      : ''

  useEffect(() => {
    if (pathName) {
      let key = defaultSelectedKey
      if (pathName.indexOf('dashboard') > -1) {
        key = '1'
      } else if (pathName.indexOf('change-ldap-password') > -1) {
        key = '2'
      } else if (pathName.indexOf('view-users') > -1) {
        key = '3'
      }
      setDefaultSelectedKey(key)
    }
  }, [pathName])
  return (
    <>
      <div className="sidebar-logo">
        <img className="img" src={logo} />
      </div>
      <Sider key="sidebar1" width={250} className="site-layout-background">
        <Menu
          key={'defaultSelectedKey_' + JSON.stringify(defaultSelectedKey)}
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKey]}
          style={{ height: '100%', borderRight: 0 }}
        >
          <Menu.Item
            title="Campaign"
            key="1"
            icon={
              <i
                style={{ marginRight: '10px', marginLeft: '1px' }}
                className="fa fa-home sidebar-icons"
                aria-hidden="true"
              ></i>
            }
          >
            Dashboard
            <Link to="/dashboard" />
          </Menu.Item>
          <Menu.Item
            title="Campaign"
            key="2"
            icon={
              <i className="fa fa-key sidebar-icons" aria-hidden="true"></i>
            }
          >
            Change LDAP Password
            <Link to={'/change-ldap-password/' + currentUserId} />
          </Menu.Item>
          {currentRole.role === ROLES.ADMIN && (
            <Menu.Item
              title="Campaign"
              key="3"
              icon={
                <i className="fa fa-users sidebar-icons" aria-hidden="true"></i>
              }
            >
              LDAP Users
              <Link to="/view-users" />
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    </>
  )
}

export default Sidebar
