export const MESSAGE_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING'
}
export const LOGIN_DETAILS = 'loginDetails'
export const UNAUTHORIZED_STATUS_CODES = [401, 403]
export const UNAUTHORIZED_CODE = 'error.forbidden'
export const ACCESS_DENIED_MSG = 'You do not have access to this page.'
export const UNKNOWN_ERROR_MSG = 'Unknown error occured.'
export const EXPIRED_TOKEN_CODE = 'token.invalidExchnageToken'
export const ACCESS_DENIED_CODE = 403
export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 100,
  pageSizeOptions: ['50', '100', '200'],
  showSizeChanger: true
}
export const ROLES = {
  ADMIN: 'ADMIN'
}
export const LOGOUT_URL = '/logout?t='
export const INVALID_URL_MSG =
  'URL is invalid. You will be redirected to login page after 10 seconds.'
