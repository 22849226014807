import React from 'react'
import { Layout, Menu, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import './header-layout.scss'
import { clearStorage, getLocalStorage } from '../../services/session-service'
import {
  LOGIN_DETAILS,
  LOGOUT_URL
} from '../../services/constant-veriable-service'
import { apiBaseUrl, getSessionToken } from '../../utils/api-utils'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuth, setCurrentUser } from '../../slices/auth-slice'
const { Header } = Layout
const HeaderLayout = () => {
  const dispatch = useDispatch()
  const authObj = useSelector(selectAuth)
  const menu = (
    <Menu icon={<DownOutlined />}>
      <Menu.Item
        key="logout_link"
        className="header-menu-item"
        onClick={(e) => doLogout(e)}
      >
        <div>
          <i className="fa fa-sign-out dropdown-icons" aria-hidden="true"></i>
          <span className="dropdown-list-items">Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  )

  const doLogout = (e) => {
    dispatch(
      setCurrentUser({
        currentUser: { email: null, userId: null }
      })
    )
    clearStorage()
    window.location.href = apiBaseUrl() + LOGOUT_URL + getSessionToken()
  }
  return (
    <>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Dropdown overlay={menu} trigger={['click']} style={{ width: '30px' }}>
          <a className="ant-dropdown-link ">
            {getLocalStorage(LOGIN_DETAILS) &&
            getLocalStorage(LOGIN_DETAILS).fullName
              ? getLocalStorage(LOGIN_DETAILS).fullName
              : authObj && authObj.currentUser && authObj.currentUser.fullName
              ? authObj.currentUser.fullName
              : 'UserName'}
            <DownOutlined style={{ marginLeft: '10px' }} />
          </a>
        </Dropdown>
      </Header>
    </>
  )
}

export default HeaderLayout
