import { createSlice } from '@reduxjs/toolkit'

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    value: {
      message: '',
      type: '',
      showMessage: false
    }
  },
  reducers: {
    setMessage: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setMessage } = messageSlice.actions

export const selectMessage = (state) => state.message.value

export default messageSlice.reducer
