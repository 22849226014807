import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'

const PopupComponent = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(props.isOpen)
  const handleOk = () => {
    setIsModalVisible(false)
    if (props.setOpenPopup) {
      props.setOpenPopup(false)
    }
    if (props.handleOk) {
      props.handleOk()
    }
  }

  const handleCancel = () => {
    if (!props.disableClosing) {
      setIsModalVisible(false)
      if (props.setOpenPopup) {
        props.setOpenPopup(false)
      }
    }
  }
  useEffect(() => {
    setIsModalVisible(props.isOpen)
  }, [props.isOpen])
  const defaultFooter = [
    <Button
      className="primary-button"
      type="primary"
      key="Yes"
      onClick={() => handleOk()}
      icon={
        <i
          className="fa fa-sign-out popup-btn-icons"
          style={{ marginRight: '10px' }}
          aria-hidden="true"
        ></i>
      }
    >
      {props.disableClosing ? 'Logout Immediately' : 'Yes'}
    </Button>,
    <Button
      className="secondary-button"
      style={{
        color: 'white',
        fontSize: '15px',
        padding: '.25em 1.1em .25em 2.2em',
        display: props.disableClosing ? 'none' : 'inline'
      }}
      key="No"
      type="secondary"
      onClick={() => handleCancel()}
      icon={<i className="fa fa-times popup-btn-icons" aria-hidden="true"></i>}
    >
      No
    </Button>
  ]
  return (
    <>
      <Modal
        className={props.className ? props.className : ''}
        title={props.popupTitle}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={props.footer ? props.footer : defaultFooter}
      >
        {props.children}
      </Modal>
    </>
  )
}
PopupComponent.propTypes = {
  popupTitle: PropTypes.any,
  handleOk: PropTypes.func,
  children: PropTypes.any,
  setOpenPopup: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.any,
  footer: PropTypes.any,
  disableClosing: PropTypes.bool
}
export default PopupComponent
