import React from 'react'

const Dashboard = () => {
  return (
    <>
      <h1>Dashboard</h1>

      <h1 className="comingSoon">Coming Soon</h1>
    </>
  )
}

export default Dashboard
