import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import PageNotFound from './pages/page-not-found'
import LoggedInLayout from './components/common/logged-in-layout'
import ProtectedRoute from './components/common/protected-route'
import './components/common/common.scss'
import './App.scss'
import Message from './components/common/message'
import ApiErrorPopup from './components/common/api-error-popup'

function App() {
  document.title = 'MI Ops'
  return (
    <>
      <Message />
      <ApiErrorPopup />
      <Router>
        <div className="App">
          <Switch>
            <ProtectedRoute exact path="/" component={LoggedInLayout} />
            <ProtectedRoute
              exact
              path="/dashboard"
              component={LoggedInLayout}
            />
            <ProtectedRoute
              exact
              path="/change-ldap-password/:userId"
              component={LoggedInLayout}
            />
            <ProtectedRoute
              exact
              path="/view-users"
              component={LoggedInLayout}
            />
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  )
}

export default App
