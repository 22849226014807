import axios from 'axios'

const SESSION_TOKEN = 'SESSION_TOKEN'

export function setSessionToken(sessionToken) {
  localStorage.setItem(SESSION_TOKEN, sessionToken)
}

export function getSessionToken() {
  return localStorage.getItem(SESSION_TOKEN)
}

export function apiBaseUrl() {
  axios.defaults.withCredentials = true
  return process.env.REACT_APP_API_ENDPOINT
}

function getConfigWithToken() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getSessionToken()
    }
  }
}

function getConfigWithTokenForBlob() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getSessionToken()
    },
    responseType: 'blob'
  }
}

function getConfig() {
  return {
    headers: {
      'Content-Type': 'application/json'
    }
  }
}

export function get(url) {
  const fullUrl = apiBaseUrl() + url
  return new Promise((resolve, reject) => {
    axios
      .get(fullUrl, getConfig())
      .then((response) => {
        resolve(response)
      })
      .catch((e) => reject(e))
  })
}

export function getWithAuth(url) {
  const fullUrl = apiBaseUrl() + url
  return new Promise((resolve, reject) => {
    axios
      .get(fullUrl, getConfigWithToken())
      .then((response) => {
        resolve(response)
      })
      .catch((e) => reject(e))
  })
}

export function excelWithAuth(url, fileName = 'excel.xlsx') {
  const fullUrl = apiBaseUrl() + url
  return new Promise((resolve, reject) => {
    axios
      .get(fullUrl, getConfigWithTokenForBlob())
      .then((response) => {
        downloadFile(
          response.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileName
        )
      })
      .catch((e) => reject(e))
  })
}

const downloadFile = (data, contentType, fileName) => {
  const blob = new Blob([data], { type: contentType })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  document.body.appendChild(link)
  link.download = fileName
  link.click()
  setTimeout(() => {
    document.body.removeChild(link)
    link.remove()
  }, 1000)
}

export function post(url, body) {
  const fullUrl = apiBaseUrl() + url

  return new Promise((resolve, reject) => {
    axios
      .post(fullUrl, body, getConfig())
      .then((response) => {
        resolve(response)
      })
      .catch((e) => reject(e))
  })
}

export function postWithAuth(url, body) {
  const fullUrl = apiBaseUrl() + url
  return new Promise((resolve, reject) => {
    axios
      .post(fullUrl, body, getConfigWithToken())
      .then((response) => {
        resolve(response)
      })
      .catch((e) => reject(e))
  })
}

export function put(url, body) {
  const fullUrl = apiBaseUrl() + url
  return new Promise((resolve, reject) => {
    axios
      .put(fullUrl, body, getConfig())
      .then((response) => {
        resolve(response)
      })
      .catch((e) => reject(e))
  })
}

export function uploadMediaOnS3(url, files) {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', files.type)
    const file = files
    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: file,
      redirect: 'follow'
    }
    fetch(url, requestOptions)
      .then((response) => resolve(response))
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error))
  })
}

export function putWithAuth(url, body) {
  const fullUrl = apiBaseUrl() + url
  return new Promise((resolve, reject) => {
    axios
      .put(fullUrl, body, getConfigWithToken())
      .then((response) => {
        resolve(response)
      })
      .catch((e) => reject(e))
  })
}

export function deleteWithAuth(url) {
  const fullUrl = apiBaseUrl() + url
  return new Promise((resolve, reject) => {
    axios
      .delete(fullUrl, getConfigWithToken())
      .then((response) => {
        resolve(response)
      })
      .catch((e) => reject(e))
  })
}
