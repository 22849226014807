import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: {
      currentUser: { email: null, userId: null }
    }
  },
  reducers: {
    setCurrentUser: (state, action) => {
      console.log(state.value)
      console.log(action)
      state.value = action.payload
    }
  }
})

export const { setCurrentUser } = authSlice.actions

export const selectAuth = (state) => state.auth.value

export default authSlice.reducer
