import React, { useEffect } from 'react'
import { Layout, Affix } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import Sidebar from './sidebar'
import HeaderLayout from './header-layout'
import Dashboard from '../../pages/dashboard'
import FooterPage from './footer'
import { exchangeToken, setLocalStorage } from '../../services/session-service'
import { getSessionToken, setSessionToken } from '../../utils/api-utils'
import {
  INVALID_URL_MSG,
  LOGIN_DETAILS
} from '../../services/constant-veriable-service'
import ChangeLdapPassword from '../../pages/change-ldap-password'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../slices/auth-slice'
import UserList from '../../pages/user-list'
import { setApiError } from '../../slices/api-error-slice'

const { Content } = Layout

const preferAdminRoleIfPresent = (roles) => {
  if (roles.length === 0) {
    return ''
  }

  if (roles.length === 1) {
    return roles[0]
  }

  let roleToReturn = ''
  for (let i = 0; i < roles.length; i++) {
    const role = roles[i]
    if (role.role === 'ADMIN') {
      return role
    } else {
      roleToReturn = role
    }
  }

  return roleToReturn
}

const LoggedInLayout = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { userId } = useParams()
  useEffect(() => {
    const url = new URL(window.location.href)
    let t = url.searchParams.get('t')
    if (!t) {
      if (!getSessionToken()) {
        dispatch(
          setApiError({
            generalError: INVALID_URL_MSG,
            apiError: null,
            isReceived: true
          })
        )
      }
      return
    }
    if (t.endsWith('/')) {
      t = t.substring(0, t.length - 1)
    }
    if (!getSessionToken()) {
      exchangeToken(t)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.apiToken &&
            response.data.apiToken.id
          ) {
            setSessionToken(response.data.apiToken.id)
            const fullName =
              (response.data.user.firstName
                ? response.data.user.firstName
                : '') +
              (response.data.user.lastName
                ? ' ' + response.data.user.lastName
                : '')
            const currentRole =
              response.data.user.roles && response.data.user.roles.length > 0
                ? preferAdminRoleIfPresent(response.data.user.roles)
                : ''
            setLocalStorage(
              LOGIN_DETAILS,
              JSON.stringify({
                userName: response.data.user.ldapUserName,
                token: response.data.apiToken.id,
                userId: response.data.apiToken.userId,
                email: response.data.user.email,
                fullName: fullName,
                role: currentRole
              })
            )
            dispatch(
              setCurrentUser({
                currentUser: {
                  email: response.data.user.email,
                  userId: response.data.apiToken.userId,
                  fullName: fullName,
                  role: currentRole
                }
              })
            )
          }
        })
        .catch((error) => {
          dispatch(
            setApiError({
              apiError: JSON.stringify(error.response),
              isReceived: true
            })
          )
        })
    }
  }, [])

  function renderMainComponent(path) {
    switch (path) {
      case '/change-ldap-password/' + userId:
        return <ChangeLdapPassword />
      case '/view-users':
        return <UserList />
      default:
        return <Dashboard />
    }
  }

  return (
    <>
      <Layout className="logged-in-layout">
        <Layout>
          <Affix offsetTop={0}>
            <Sidebar />
          </Affix>
          <Layout className="site-layout">
            <Affix offsetTop={0}>
              <HeaderLayout />
            </Affix>
            <Content style={{ margin: '10px 16px' }}>
              {renderMainComponent(location.pathname)}
            </Content>
            <FooterPage />
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

export default LoggedInLayout
