import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.elementType
}

export default ProtectedRoute
