import { createSlice } from '@reduxjs/toolkit'

export const apiErrorSlice = createSlice({
  name: 'apiError',
  initialState: {
    value: {
      apiError: null,
      generalError: null,
      isReceived: false
    }
  },
  reducers: {
    setApiError: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setApiError } = apiErrorSlice.actions

export const selectApiError = (state) => state.apiError.value

export default apiErrorSlice.reducer
