import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import pageNotFound from './../images/pageNotFound.jpg'
import './pageNotFound.scss'

const PageNotFound = () => {
  const history = useHistory()
  return (
    <div className="pageNotFound">
      {' '}
      <div className="go-to-main-page">
        <Button
          className="primary-button"
          type="primary"
          onClick={() => history.push('/')}
          icon={
            <i
              className="fa fa-sign-out popup-btn-icons"
              style={{ marginRight: '10px' }}
              aria-hidden="true"
            ></i>
          }
        >
          Go to main page
        </Button>
      </div>
      <img src={pageNotFound} />{' '}
      {/* <div className="form-container">
        <Row>
          <Col span={10}> */}
      {/* </Col>
        </Row>
      </div> */}
    </div>
  )
}

export default PageNotFound
