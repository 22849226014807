import { message } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MESSAGE_TYPE } from '../../services/constant-veriable-service'
import { selectMessage } from '../../slices/message-slice'
import { CloseOutlined } from '@ant-design/icons'
import './message.scss'
const Message = (props) => {
  const messageObj = useSelector(selectMessage)

  const success = () => {
    message.success({
      content: (
        <span>
          <CloseOutlined
            onClick={() => message.destroy()}
            style={{
              cursor: 'pointer',
              float: 'right',
              color: '#7c8488',
              fontSize: '12px',
              marginRight: '0px'
            }}
          />
          <div className="custom-message-area">{messageObj.message} </div>
        </span>
      ),
      duration: 3.5,
      className: 'toast-area'
    })
  }
  const error = () => {
    message.error({
      content: (
        <span>
          <CloseOutlined
            onClick={() => message.destroy()}
            style={{
              cursor: 'pointer',
              float: 'right',
              color: '#7c8488',
              fontSize: '12px',
              marginRight: '0px'
            }}
          />
          <div className="custom-message-area">{messageObj.message} </div>
        </span>
      ),
      duration: 3.5,
      className: 'toast-area'
    })
  }
  const warning = () => {
    message.warning(messageObj.message)
  }
  useEffect(() => {
    if (messageObj.showMessage) {
      if (messageObj.type === MESSAGE_TYPE.SUCCESS) {
        success()
      } else if (messageObj.type === MESSAGE_TYPE.ERROR) {
        error()
      } else {
        warning()
      }
    }
  }, [messageObj])

  return <div></div>
}

export default Message
