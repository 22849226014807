import { post, postWithAuth } from '../utils/api-utils'

export function exchangeToken(tokenToExchange) {
  return post('/api/v1/session/exchange-token', {
    exchangeToken: tokenToExchange
  })
}
export function changePassword(newPassword, userId) {
  return postWithAuth('/api/v1/ldap-users/' + userId + '/update-password', {
    newPassword: newPassword
  })
}
export function clearStorage() {
  localStorage.clear()
}

export function setLocalStorage(itemKey, itemValue) {
  localStorage.setItem(itemKey, itemValue)
}

export function getLocalStorage(itemKey) {
  return localStorage.getItem(itemKey)
    ? JSON.parse(localStorage.getItem(itemKey))
    : null
}
