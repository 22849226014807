import {
  ACCESS_DENIED_CODE,
  ACCESS_DENIED_MSG,
  EXPIRED_TOKEN_CODE,
  UNAUTHORIZED_STATUS_CODES,
  UNAUTHORIZED_CODE,
  UNKNOWN_ERROR_MSG
} from '../services/constant-veriable-service'

export function shouldSubmit(event) {
  return event !== undefined && event.keyCode === 13
}
function getErrorContent(error) {
  if (
    error &&
    error.data &&
    error.data.errors &&
    error.data.errors.general &&
    error.data.errors.general.length > 0 &&
    error.data.errors.general[0] &&
    error.data.errors.general[0]
  ) {
    return error.data.errors.general[0]
  } else {
    return null
  }
}

export function getErrorMessage(error) {
  let msg = 'Something went wrong.'
  if (error.status && error.status === ACCESS_DENIED_CODE) {
    msg = ACCESS_DENIED_MSG
  } else if (getErrorContent(error) && getErrorContent(error).message) {
    msg = getErrorContent(error).message
  } else if (error && error.message) {
    msg = error.message
  } else if (
    getErrorContent(error) &&
    getErrorContent(error).message &&
    getErrorContent(error).message.indexOf('Exception') > -1
  ) {
    msg = UNKNOWN_ERROR_MSG
  }
  return msg
}
export function checkUnauthorizedAccess(error) {
  let unauthorized = false
  if (
    error &&
    ((error.status && UNAUTHORIZED_STATUS_CODES.includes(error.status)) ||
      (getErrorContent(error) &&
        getErrorContent(error).messageCode &&
        (getErrorContent(error).messageCode === UNAUTHORIZED_CODE ||
          getErrorContent(error).messageCode === EXPIRED_TOKEN_CODE)))
  ) {
    unauthorized = true
  }
  return unauthorized
}
