import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setMessage } from '../slices/message-slice'
import PropTypes from 'prop-types'
import { Input, Button, Modal, Form, Spin } from 'antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { addUser } from '../services/user-service'
import { MESSAGE_TYPE } from '../services/constant-veriable-service'
import { setApiError } from '../slices/api-error-slice'

const AddUser = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const formValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .min(2, 'First Name should be atleast 2 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .min(2, 'Last Name should be atleast 2 characters'),
    email: Yup.string()
      .required('Email is required')
      .email('Please enter a valid email')
      .matches(
        /^[a-z]+(\.)*[a-z]*[0-9]*@mobisoftinfotech.com$/,
        'Only emails from mobisoftinfotech.com are allowed.'
      ),
    ldapUidSuffix: Yup.number()
      .required('LDAP UID suffix is required')
      .min(209, 'LDAP UID suffix should be greater than 208')
      .max(9999, 'LDAP User Suffix should be less than or equal to 9999.'),
    password: Yup.string()
      .required('Password is required')
      .min(12, 'Password should be atleast 12 characters')
      .matches(
        /^(?=.{12,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/,
        'Password must have 1 upper case, 1 lower case, 1 digit and 1 symbol from @#$%^&+=.'
      ),
    confirmPassword: Yup.string()
      .required('Please enter password again')
      .oneOf([Yup.ref('password'), null], 'Passwords must be same')
  })

  const formikRef = useRef()

  const resetAndClose = () => {
    formikRef.current?.resetForm()
    props.handleCancel()
  }

  const showMessage = (message, type) => {
    dispatch(
      setMessage({
        message: message,
        type: type,
        showMessage: true
      })
    )
  }

  return (
    <>
      {/* form-modal */}
      <Modal
        title="Add User"
        visible={props.isModalVisible}
        onOk={props.handleOk}
        onCancel={resetAndClose}
        footer={[
          <Button key="back" onClick={resetAndClose}>
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              formikRef.current?.handleSubmit()
            }}
          >
            Add User
          </Button>
        ]}
      >
        {loading && (
          <div className="loader-class">
            <Spin size="large" />
          </div>
        )}
        <Formik
          innerRef={formikRef}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            ldapUidSuffix: '',
            password: '',
            confirmPassword: ''
          }}
          enableReinitialize={true}
          validationSchema={formValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setLoading(true)
            addUser(values)
              .then((response) => {
                setSubmitting(false)
                if (response) {
                  setLoading(false)
                  resetAndClose()
                  showMessage(
                    'New user was added successfully.',
                    MESSAGE_TYPE.SUCCESS
                  )
                }
              })
              .catch((error) => {
                setLoading(false)
                setSubmitting(false)
                dispatch(
                  setApiError({
                    apiError: JSON.stringify(error.response),
                    isReceived: true
                  })
                )
              })
          }}
        >
          {({ handleChange, handleSubmit, values, errors }) => (
            <Form layout="vertical" onSubmit={handleSubmit}>
              <Form.Item label="First Name">
                <Input
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
                <p className="user-form-error">{errors.firstName}</p>
              </Form.Item>
              <Form.Item label="Last Name">
                <Input
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
                <p className="user-form-error">{errors.lastName}</p>
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <p className="user-form-error">{errors.email}</p>
              </Form.Item>
              <Form.Item label="LDAP UID Suffix">
                <Input
                  name="ldapUidSuffix"
                  value={values.ldapUidSuffix}
                  onChange={handleChange}
                />
                <p className="user-form-error">{errors.ldapUidSuffix}</p>
              </Form.Item>
              <Form.Item label="Password">
                <span className="passwordCriteria">
                  Note: Password must have at least 12 characters including 1
                  upper case, 1 lower case, 1 digit and 1 symbol from @#$%^&+=.
                </span>
                <Input.Password
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
                <p className="user-form-error">{errors.password}</p>
              </Form.Item>
              <Form.Item label="Confirm Password">
                <Input.Password
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                />
                <p className="user-form-error">{errors.confirmPassword}</p>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

AddUser.propTypes = {
  isModalVisible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func
}

export default AddUser
