import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth-slice'
import messageReducer from './slices/message-slice'
import apiErrorReducer from './slices/api-error-slice'
export default configureStore({
  reducer: {
    auth: authReducer,
    message: messageReducer,
    apiError: apiErrorReducer
  }
})
