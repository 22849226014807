import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MESSAGE_TYPE } from '../../services/constant-veriable-service'
import { selectApiError, setApiError } from '../../slices/api-error-slice'
import { setMessage } from '../../slices/message-slice'
import {
  checkUnauthorizedAccess,
  getErrorMessage
} from '../../utils/common-functions'
import ErrorPopup from './error-popup'
const ApiErrorPopup = (props) => {
  const error = useSelector(selectApiError)
  const dispatch = useDispatch()
  const [isOpen, setOpenPopup] = useState(false)
  const [customMessage, setCustomMessage] = useState(false)
  const showMessage = (message, type) => {
    dispatch(
      setMessage({
        message: message,
        type: type,
        showMessage: true
      })
    )
  }
  useEffect(() => {
    if (error && error.apiError && error.isReceived) {
      const parsedApiError = JSON.parse(error.apiError)
      showMessage(getErrorMessage(parsedApiError), MESSAGE_TYPE.ERROR)
      if (checkUnauthorizedAccess(parsedApiError)) {
        setOpenPopup(true)
      }
      dispatch(
        setApiError({
          apiError: null,
          isReceived: false
        })
      )
    }
    if (error && error.generalError && error.isReceived) {
      setOpenPopup(true)
      setCustomMessage(error.generalError)
      dispatch(
        setApiError({
          apiError: null,
          isReceived: false
        })
      )
    }
  }, [error.isReceived])
  return <ErrorPopup isOpen={isOpen} message={customMessage}></ErrorPopup>
}

export default ApiErrorPopup
