import React from 'react'
import { Footer } from 'antd/lib/layout/layout'
import { Col, Row } from 'antd'
import './footer.scss'

const FooterPage = () => {
  return (
    <Footer className="login-footer" style={{ textAlign: 'center' }}>
      <Row>
        <Col span={24}>
          <div className="copyright">
            © 2021 - Mobisoft Infotech Pvt. Ltd.
            <br />
            Version: 1.0.1
          </div>
        </Col>
      </Row>
    </Footer>
  )
}

export default FooterPage
