import React, { useState, useEffect } from 'react'
import { Col, Card, Input, Table, Row, Tooltip, Button } from 'antd'
import { fetchUserList } from '../services/user-service'
import { useHistory } from 'react-router-dom'
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons'
import { DEFAULT_PAGINATION } from '../services/constant-veriable-service'
import { useDispatch } from 'react-redux'
import { shouldSubmit } from '../utils/common-functions'
import { setApiError } from '../slices/api-error-slice'
import AddUser from '../components/add-user'

const UserList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [searchedText, setSearchedText] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
    handleResetChange()
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const [sorter] = useState({
    sortField: 'firstName',
    sortOrder: 'ascend'
  })
  const [tableData, setTableData] = useState({
    data: [],
    pagination: DEFAULT_PAGINATION
  })
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: true,
      width: '20%',
      render: function profiles(_, record) {
        return <div>{record.firstName ? record.firstName : ''}</div>
      }
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: true,
      width: '20%',
      render: function profiles(_, record) {
        return <div>{record.lastName ? record.lastName : ''}</div>
      }
    },
    {
      title: 'Email',
      sorter: true,
      dataIndex: 'email',
      width: '35%',
      render: function profiles(_, record) {
        return <div>{record && record.email ? record.email : ''}</div>
      }
    },
    {
      title: 'LDAP User Name',
      sorter: true,
      dataIndex: 'ldapUserName',
      width: '25%',
      render: function profiles(_, record) {
        return <span>{record.ldapUserName ? record.ldapUserName : ''}</span>
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '5%',
      render: function actions(_, record) {
        return (
          <span>
            <Tooltip
              title="Change password"
              color={'gray'}
              key={'change_password'}
            >
              <i
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/change-ldap-password/' + record.id)
                }}
                className="fa fa-key list-icons handPointer"
                aria-hidden="true"
              ></i>
            </Tooltip>
          </span>
        )
      }
    }
  ]

  useEffect(() => {
    const { pagination } = tableData
    if (tableData.data && tableData.data.length === 0) {
      fetch({ pagination, ...sorter })
    }
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    })
  }

  const fetch = (params = {}) => {
    if (params && params.pagination) {
      const { pagination } = tableData
      if (pagination.pageSize !== params.pagination.pageSize) {
        params.pagination.current = 1
      }

      const searchKeyword =
        params.pagination.search !== undefined ? params.pagination.search : ''
      const urlParameters = `limit=${params.pagination.pageSize}&offset=${
        params.pagination.pageSize * (params.pagination.current - 1)
      }&sortColumn=${
        params.sortField ? params.sortField : 'firstName'
      }&sortType=${
        params.sortOrder === 'descend' ? 'desc' : 'asc'
      }&search=${searchKeyword}`
      setLoading(true)
      fetchUserList(urlParameters)
        .then((response) => {
          if (response && response.data && response.data.records) {
            setTableData({
              data: response.data.records,
              pagination: {
                ...params.pagination,
                total: response.data.pagination.totalRecords
                  ? response.data.pagination.totalRecords
                  : 0
              }
            })
          }
          setLoading(false)
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          setLoading(false)
          dispatch(
            setApiError({
              apiError: JSON.stringify(error.response),
              isReceived: true
            })
          )
        })
    }
  }

  const { data, pagination } = tableData
  const onSearch = (event) => setSearchedText(event.target.value)
  const handleSearchEvent = () => {
    fetch({ pagination: { ...DEFAULT_PAGINATION, search: searchedText } })
  }
  const handleResetChange = () => {
    fetch({ pagination: { ...DEFAULT_PAGINATION, search: '' } })
    setSearchedText('')
  }
  return (
    <>
      <Card bordered={false}>
        <Row>
          <Col span={7}>
            <Input
              className="search-input"
              style={{ width: '100%' }}
              size="large"
              placeholder="Search User..."
              value={searchedText}
              onChange={onSearch}
              onKeyDown={(event) => {
                shouldSubmit(event) && handleSearchEvent()
              }}
              suffix={
                searchedText ? (
                  <CloseCircleFilled
                    className="handPointer"
                    onClick={handleResetChange}
                    style={{ color: '#00000040', fontSize: '13px' }}
                  />
                ) : (
                  ''
                )
              }
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col span={3} offset={1}>
            <Button
              style={{ width: '100%' }}
              size="large"
              type="primary"
              key="search_user"
              onClick={() => handleSearchEvent()}
            >
              Search
            </Button>
          </Col>
          <Col span={3} offset={1}>
            <Button
              style={{ width: '100%' }}
              size="large"
              type="secondary"
              key="search_user"
              onClick={() => handleResetChange()}
            >
              Clear
            </Button>
          </Col>
          {/* add user */}
          <Col span={3} offset={6}>
            <Button
              style={{ width: '100%' }}
              size="large"
              type="primary"
              key="search_user"
              onClick={showModal}
            >
              Add User
            </Button>
          </Col>
        </Row>
        <div className="cardSpacing"></div>
        <div>
          <Table
            columns={columns}
            rowKey={(record) => record.ldapUserName}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>

        <AddUser
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </Card>
    </>
  )
}

export default UserList
