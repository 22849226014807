import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Form, Input, Button, Row, Col, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { setMessage } from '../slices/message-slice'
import { MESSAGE_TYPE } from '../services/constant-veriable-service'
import { changePassword } from '../services/session-service'
import { shouldSubmit } from '../utils/common-functions'
import ErrorPopup from '../components/common/error-popup'
import { useParams } from 'react-router-dom'
import { setApiError } from '../slices/api-error-slice'

const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Password is required.')
    .min(12, 'Password should be grater than 12 characters.')
    .max(40, 'Password should be less than 40 characters.')
    .matches(
      /^(?=.{12,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/,
      'Password must have 1 upper case, 1 lower case, 1 digit and 1 symbol from @#$%^&+=.'
    )
})

const ChangeLdapPassword = () => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [loading, setLoading] = useState(false)
  const [isOpen] = useState(false) // setOpenPopup

  const showMessage = (message, type) => {
    dispatch(
      setMessage({
        message: message,
        type: type,
        showMessage: true
      })
    )
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <h1>Change LDAP Password</h1>
      <div className="form-wrapper">
        <Formik
          initialValues={{
            newPassword: '',
            size: 'default'
          }}
          enableReinitialize={true}
          validationSchema={ChangePasswordSchema}
          onSubmit={(values, { setSubmitting }) => {
            setLoading(true)
            changePassword(values.newPassword, userId)
              .then((response) => {
                setSubmitting(false)
                if (response) {
                  setLoading(false)
                  showMessage(
                    'New Password updated successfully.',
                    MESSAGE_TYPE.SUCCESS
                  )
                }
              })
              .catch((error) => {
                setLoading(false)
                setSubmitting(false)
                dispatch(
                  setApiError({
                    apiError: JSON.stringify(error.response),
                    isReceived: true
                  })
                )
              })
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form
              layout={'vertical'}
              className="form-container"
              onSubmit={handleSubmit}
            >
              <Row>
                {loading && (
                  <div className="loader-class">
                    <Spin size="large" />
                  </div>
                )}
                <Col span={24}>
                  <div className="common-add-form-label">
                    New Password{' '}
                    <span className="mandatory-astrik">&nbsp;*</span>
                  </div>
                  <span className="passwordCriteria">
                    Note: Password must have at least 12 characters including 1
                    upper case, 1 lower case, 1 digit and 1 symbol from
                    @#$%^&+=.
                  </span>
                </Col>
                <Col span={10}>
                  <Input.Password
                    className="common-text-field"
                    autoComplete="off"
                    placeholder="New Password"
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    onKeyDown={(event) => {
                      shouldSubmit(event) &&
                        !isSubmitting &&
                        values.newPassword &&
                        handleSubmit()
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="form-error">
                    {errors.newPassword &&
                      touched.newPassword &&
                      errors.newPassword}
                  </div>
                </Col>
              </Row>
              <Form.Item key="update_button">
                <Row>
                  <Col span={10}>
                    <Button
                      className={`common-btn full-size-button${
                        !isSubmitting && values.newPassword
                          ? ''
                          : ' disable-button'
                      }`}
                      type="primary"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          )}
        </Formik>
        <ErrorPopup isOpen={isOpen}></ErrorPopup>
      </div>
    </>
  )
}

export default ChangeLdapPassword
